import React, { Component } from "react";
import PrintHeader from "../../../Prints/PrintHeader/PrintHeader";
class RepurUnitsReportPrint extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div className="row m-0 p-0">
          <PrintHeader />
        </div>
        <div className="row m-3 p-0">
          <div className="col-12 text-center mt-2">
            <h4 style={{ color: "red" }}>
              <u>Repurchased Units Summary Report</u>
            </h4>

            <div className="mt-5">
              <div class=" col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Payable Amount</th>
                      <th scope="col">Paid Amount</th>
                      <th scope="col">Pending Amount</th>
                      <th scope="col">Profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.printData !== undefined
                      ? this.props.printData?.map((x, id) => (
                          <tr key={id}>
                            <td>{id + 1}</td>
                            <td>{x.UnitName}</td>
                            <td>{x.CustomerName}</td>
                            <td>{x.PayableAmount?.toLocaleString()}</td>
                            <td>{x.PaidAmount?.toLocaleString()}</td>
                            <td>{x.PendingAmount?.toLocaleString()}</td>
                            <td>{x.ProfitOrLoss?.toLocaleString()}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RepurUnitsReportPrint;
