const repurchasedUnitsSummaryreport = { data: [], loading: true };
export const GetRepurchasedUnitsSummaryreport = (
  state = repurchasedUnitsSummaryreport,
  action
) => {
  switch (action.type) {
    case "get_allRepurchasedUnitsSummaryreport":
      return {
        data: action.payload,
        loading: action.loading,
      };
    default:
      return state;
  }
};
