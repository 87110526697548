import { useEffect, useRef } from "react";
import { useState } from "react";
import { TiPrinter } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { getRepurchasedUnitsSummaryreport } from "../../../actions/AccountReports/RepurchasedUnitsSummary";
import { CSVLink } from "react-csv";
import Loader from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import RepurUnitsReportPrint from "../ReportPrints/RepurUnitsReportPrint";

const RepurchasedUnitsSummary = () => {
  const [csvData, setcsvData] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const componentRef = useRef();

  const repurchasedUnitsSummaryreport = useSelector(
    (state) => state.GetRepurchasedUnitsSummaryreport
  );
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const dispatch = useDispatch();

  const onTextChange = (e) => {
    if (
      document.getElementById("unitfilter").value === "" &&
      document.getElementById("CustomerNamefilter").value === ""
    ) {
      setfilterdata(repurchasedUnitsSummaryreport.data);
    } else {
      let suggestions = [];
      {
        const regex1 = new RegExp(
          document.getElementById("CustomerNamefilter").value,
          "i"
        );
        const regex2 = new RegExp(document.getElementById("unitfilter").value, "i");

        suggestions = repurchasedUnitsSummaryreport.data
          .sort()
          .filter(
            (val) =>
              (document.getElementById("CustomerNamefilter").value === "" ||
                regex1.test(val.CustomerName)) &&
              (document.getElementById("unitfilter").value === "" ||
                regex2.test(val.UnitName))
          );
      }
      setfilterdata(suggestions);
    }
  };

  useEffect(() => {
    dispatch(getRepurchasedUnitsSummaryreport());
  }, [dispatch]);

  useEffect(() => {
    setfilterdata(repurchasedUnitsSummaryreport.data);
  }, [repurchasedUnitsSummaryreport]);

  useEffect(() => {
    let arr = [];
    filterdata?.forEach((item) => {
      arr.push({
        Unit: item?.UnitName,
        ["Customer Name"]: item?.CustomerName,
        ["Payable Amount"]: item?.PayableAmount?.toLocaleString(),
        ["Paid Amount"]: item?.PaidAmount?.toLocaleString(),
        ["Pending Amount"]: item?.PendingAmount?.toLocaleString(),
        Profit: item?.ProfitOrLoss?.toLocaleString(),
      });
    });
    setcsvData(arr);
  }, [filterdata]);
  return (
    <>
      <div hidden>
        <RepurUnitsReportPrint
          ref={componentRef}
          printData={filterdata !== undefined ? filterdata : null}
        />
      </div>
      <div className="row  app_font">
        <div className="dash_background col-12 pt-2">
          <div className="row ">
            <div className="col-md-1 pr-0 pl-0">
              <label className="input_label m-0">Search:</label>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="unitfilter"
                onChange={onTextChange}
                placeholder="Unit"
              ></input>
            </div>
            <div className="col-md-3 pr-0 pl-0">
              <input
                type="text "
                className="input_styling "
                id="CustomerNamefilter"
                onChange={onTextChange}
                placeholder="Customer Name"
              ></input>
            </div>

            <div className="col-md-1 pr-0 pl-0">
              {repurchasedUnitsSummaryreport.loading ? (
                <Loader type="ThreeDots" color="green" height={40} width={40} />
              ) : null}
            </div>
          </div>

          <div className=" col-12  table_height pr-0 pl-0">
            <div className="table-responsive mt-2">
              <table className="table table-borderless m-0">
                <thead>
                  <tr>
                    <th scope="col">Sr</th>

                    <th scope="col">Unit</th>
                    <th scope="col">Customer Name</th>

                    <th scope="col">Payable Amount</th>
                    <th scope="col">Paid Amount</th>
                    <th scope="col">Pending Amount</th>
                    <th scope="col">Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {filterdata !== undefined
                    ? filterdata.map((x, id) => (
                        <tr key={id}>
                          <td>{id + 1}</td>
                          <td>{x.UnitName}</td>
                          <td>{x.CustomerName}</td>
                          <td>{x.PayableAmount?.toLocaleString()}</td>
                          <td>{x.PaidAmount?.toLocaleString()}</td>
                          <td>{x.PendingAmount?.toLocaleString()}</td>
                          <td>{x.ProfitOrLoss?.toLocaleString()}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6  text-right pr-0 pl-0">
            <button
              className="print_btn float-left"
              onClick={() => {
                printPaymentRec();
              }}
            >
              <TiPrinter size="1.3em" />
              Print
            </button>
            {csvData.length > 0 && (
              <button
                style={{ color: "black !important" }}
                className="print_btn ml-2 float-left"
              >
                <CSVLink
                  style={{ color: "black" }}
                  filename="Repurchased Units Summary"
                  data={csvData}
                >
                  Download CSV
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RepurchasedUnitsSummary;
